<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增返利类型':'修改返利类型'" :close-on-click-modal="false" @closed="cancel" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-suffix="：" label-width="92px" v-if="form" :disabled="disabled">
        <el-form-item label="类型名称" prop="name">
          <el-input v-model.trim="form.name" :maxlength="20" />
        </el-form-item>
        <el-form-item label="类型描述" prop="remark">
          <el-input type="textarea" v-model.trim="form.remark" :rows="3" resize="none" :maxlength="100" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/rebateType";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: null,
      rules: {
        name: [
          { required: "true", message: "类型名称为必填项", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          add(this.form)
            .then(res => {
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        remark: ""
      };
    }
  }
};
</script>

